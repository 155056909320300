.landing{
    background-color: var(--primary-color-pink);
    margin-top: -160px;
   
    border-radius: 0px 0px 75px 75px;
    border: 4px solid var(--primary-shade-pink);
    padding-bottom: 0px;
    padding-top: 200px;
    
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
}
.landing-img{
    width: 100%;   
}
.img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
}


.homepage-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 100px 5%; 
    margin-bottom: 0px;
}

.homepage-section > .image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.homepage-section > .image > img {
    width: 100%;
}

.homepage-section > .image > img.bunnyHome {
    max-width: 300px;
}

.homepage-section.second {
    flex-wrap: wrap-reverse;
}

.homepage.case-studies-section {
    background-color: var(--neutral-color-lightGrey);
}

@media (max-width: 900px) {
    .bunnyHome > img {
        width: 10px;
        margin-top: 40px;
    }

    .homepage-section {
        margin-top: 0; 
    }
}

.button-side {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.button-side > .button {
    margin-right: 20px;
    margin-bottom: 30px;
}
@media (min-width: 991px){
    .introduction{
        padding-right: 0px;
        flex-wrap: none;
    }
}
@media (max-width: 991px) {
    
    .button-side {
        display: none;
    }

    .landing-header {
        text-align: center;
    }

    .img-container {
        width: 100%;
    }

    .landing-img {
        width: 80%;
        margin-left: 100px;
    }
}
