
.button {
    border-radius: 30px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    padding: 0.5rem 1.3rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    box-shadow: 0px 4px 0px 0px;
    text-decoration: none !important;
}
.button:active{
    box-shadow: 0px 0px 0px 0px;

}
.button:hover{
  cursor: pointer;
}
.button.full-width{
  width: 100%;
}

  
  /* Primary button style */
  .button--primary {
    background-color: var(--accent-color) !important;
    box-shadow: 0px 4px 0px 0px  var(--accent-shade) !important;
    border:  3px solid var(--accent-shade) !important;
    color: #fff !important;
  }
  .button--primary:hover {
    background-color: #548BFF;
    color: #fff !important;
  }
  .button--primary:active {
    box-shadow: var(--accent-shade);
  }
  
    /* Secondary button style */
    .button--secondary {
        background-color: var(--neutral-color-white);
        box-shadow: 0px 4px 0px 0px  var(--accent-shade);
        border:  3px solid var(--accent-shade);
        color: var(--accent-shade) !important;
      }
      .button--secondary:hover {
        background-color: var(--neutral-color-lightGrey);
        color: var(--accent-shade) !important
      }
      .button--secondary:active {
        box-shadow: var(--accent-shade);
      }


    /* Pink button style */
    .button--pink {
        background-color: var(--secondary-color-pink);
        box-shadow: 0px 4px 0px 0px  var(--secondary-shade-pink);
        border:  3px solid var(--secondary-shade-pink);
        color: var(--neutral-color-white) !important;
      }
      .button--pink:hover {
        background-color: #E967AE;
        color: var(--neutral-color-white) !important;
      }
      .button--pink:active {
        box-shadow: var(--accent-shade);
      }
      

      /* Blue button style */
    .button--blue {
        background-color: var(--primary-color-blue);
        box-shadow: 0px 4px 0px 0px  var(--primary-shade-blue);
        border:  3px solid var(--primary-shade-blue);
        color: var(--neutral-color-white) !important;
      }
      .button--blue:hover {
        background-color: #0B3098;
        color: var(--neutral-color-white) !important;
      }
      .button--blue:active {
        box-shadow: var(--accent-shade);
      }

      /* Disabled button style */
      .button--disabled {
        background-color: var(--neutral-color-grey);
        box-shadow: 0px 0px 0px 0px;
        border:  3px solid var(--neutral-shade-grey);
        color: var(--neutral-shade-grey) !important;
      }

  /* Add more styles as needed */
  