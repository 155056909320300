.navbar-container{
    padding: 2rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.navbar-link{
    color: var(--primary-color-blue) !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none !important;
}
.navbar-link.active {
    color: var(--neutral-color-black) !important;
    text-decoration: underline !important;
  }
  
.navbar-item > div{
  display: inline-block;
  padding: 0px 20px;
}

.otherpage-navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 0px 0px 20px 50px;
  z-index: 999 !important;
  background-color: var(--primary-color-pink);
}


.otherpage-navbar.scrolled {
  background-color: transparent;
  
}

.navbar-mobile{
    background-color: var(--primary-color-pink);
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    border-radius: 20px 20px 0px 0px;
    border: solid var(--primary-shade-pink);
}
.icon-container{
    height: 70px;
    width: 100%;
}
.nav-txt{
    text-align: center;
    margin-top: 0px;
    color: white;
    font-size: .7rem !important;
}
.navbar-desktop{
  position: sticky;
  top: 0;
  z-index: 999 !important;
}
@media (max-width: 1000px) {
    .navbar-desktop {
      display: none;
    }
    .navbar-mobile {
        display: flex;
        justify-content: space-between;
        z-index: 999 !important;
        
    }
    .navbar-item{
        width: 20%;
    }
  }  