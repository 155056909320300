.pfp {
    margin-right: 40px;
}

.pfp > img {
    border: solid 4px var(--primary-color-pink);
    border-radius: 50px;
    object-fit: cover;
}

.about-information {
    display: flex;
    flex-direction: row;
}

.quick-links {
    margin-top: 50px;
    justify-content: space-around !important;
   
}

.link-container {
    text-decoration: none !important;
    cursor: pointer;
    transition: ease-in-out 0.1s;
    align-items: space-between !important;

}

.link-container:hover {
    transform: scale(1.05);
}



.hobbies-container {
    display: flex;
    justify-content: space-between;
}

.hobby {
    margin-right: 20px;
}

.hobby-icon {
    margin-right: 20px;
}

.hobby-information h4 {
    margin-bottom: -20px;
}

.applications, .hobbies, .education-certificates {
    margin-top: 150px;
}

.application-container p {
    text-align: left;
}

.education{
    border: solid var(--primary-color-blue) 4px;
    border-radius: 10px;
    padding: 10px 30px;
}
.education-img, .certification-img{
    max-width: 100px;
    margin-right: 20px;
}
.certification-img{
    border: solid 3px var(--neutral-color-grey);
    border-radius: 10px;
   
}

.certificate{
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    padding: 10px;
    width: 400px;

}

@media (min-width: 900px) {
    .certificates-container{
        justify-content: space-between !important;
    }
    
}
@media (max-width: 900px) {
    .about-information {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    

    .information > h1 {
        text-align: center;
    }
}

@media (max-width: 1000px) {
    body {
        padding-bottom: 200px !important;
    }
}
