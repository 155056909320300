.card{
    display: inline-block;
    margin: 15px;
}
/*Smaller Card*/
.smaller-card{
    text-align: center;
}
.smaller-card > .thumbnail{
    width: 300px;
    height: 200px;
    object-fit: cover;
    border: 4px solid var(--primary-color-blue);
    border-radius: 22px;
    overflow: clip;

}
.smaller-card > .company-name{
    margin-top: -10px;
    margin-bottom: -10px;
}
.smaller-card > .company-type{
    color: var(--neutral-color-grey);
}
.smaller-card > .position{
    margin-bottom: 40px;
}

/*Small Card*/
.small-card{
    border: 4px solid var(--primary-color-blue);
    border-radius: 55px;
    width: 350px;
    overflow: clip;
    box-shadow: 2px 4px 0px 0px #D2599B;
    background-color: white;

}
.small-card > .top > .thumbnail{
    width: 100%;
    height: 250px;
    margin-bottom: 0px;
    object-fit: cover;
}
.small-card > .top{
    background-color: var(--primary-color-blue);
}

.small-card > .top > .company-name{
    color:white;
    margin-top: 0px;
    text-align: center;
    padding: 10px;
    font-weight: 600;
    background-color: var(--primary-color-blue);
}
.small-card > .bottom {
    padding: 10px 20px 30px 20px;
}
.small-card > .bottom > .position{
    color: var(--primary-color-blue);
    font-weight: bold;
    margin-bottom: -10px;
}
.small-card > .bottom > .link{
    font-family: "Uniform";
}

/*Big Vertical Card*/
.big-card-vertical{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.big-card-vertical > .top{
    border-radius: 30px;
    width: 100%;
    border: solid var(--primary-color-blue);
    background-color: var(--primary-color-blue);
    overflow: clip;
}
.big-card-vertical > .top > .thumbnail{
    width: 100%;
    height: 300px;
    object-fit: cover;
    
}
.big-card-vertical > .top > .company-name{
    text-align: center;
    color: white;
}
.big-card-vertical > .bottom {
    width: 100%;
}
.big-card-vertical > .bottom > .companyType{
    color: var(--neutral-color-grey);
}
.big-card-vertical > .bottom > .title{
    margin-top: -10px;
}
.big-card-vertical > .bottom > .description{
   margin-bottom: 40px;
   margin-top: -10px;
}

.big-card-horizontal{
    display: flex;
    align-items: center;
}
.big-card-horizontal > .image > img{
    width: 300px;
    height: 400px;
    object-fit: cover;
    border-radius: 30px;
    margin-right: 30px;
}
.big-card-horizontal > .information > .title{
    margin-top: -10px;
    margin-bottom: -10px;
}
.big-card-horizontal > .information > .company-name{
    margin-bottom: 40px;

}
@media (max-width: 1200px){
    .big-card-horizontal{
        display: block;
        align-items: center;
    }
    .big-card-horizontal > .image > img{
        width: 100%;
        height: 200px;
 
    }

}