
.case-study-btn{
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}
@media screen and (max-width: 1000px) {
    .case-study-section{
        padding: 0px !important;
    }
    .caseStudyInfo{
        padding: 100px;
    }
    
}

@media (min-width: 959px) {
    .case-btn-container{
        justify-content: flex-start !important;
    } 
    .case-study-btn{
        margin-right: 20px;
        width: auto;
    }
 
}